import { Popover, Select, MenuItem } from "@mui/material";
import {
  PopoverContent,
  PublishedWorkflowsHeader,
  PublishedWorkflowsBody,
  PublishedWorkflowsItem,
  PublishedWorkflowsFooter,
} from "./style";
import { TextField } from "@mui/material";
import { useState } from "react";

export const PublishedWorkflowsPopovers = ({
  anchorElPublishedWorkflows,
  onClosePublishedWorkflowsPopover,
  publishedWorkflows,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("All workflows");

  const handleSelectWorkflow = (workflow) => {
    onClosePublishedWorkflowsPopover({
      type: "select",
      workflow: workflow,
    });
  };

  // Get unique tags
  const uniqueTags = [
    "All workflows",
    ...new Set(publishedWorkflows.map((workflow) => workflow.tag)),
  ];

  const filteredWorkflows = publishedWorkflows.filter((workflow) => {
    const matchesSearch = workflow.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesTag = selectedTag === "All workflows" || workflow.tag === selectedTag;
    return matchesSearch && matchesTag;
  });

  return (
    <Popover
      open={Boolean(anchorElPublishedWorkflows)}
      anchorEl={anchorElPublishedWorkflows}
      onClose={onClosePublishedWorkflowsPopover}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "1rem",
            boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.08)",
          },
        },
      }}
    >
      <PopoverContent style={{ maxWidth: "320px" }}>
        <PublishedWorkflowsHeader>Workflow presets</PublishedWorkflowsHeader>
        <div style={{ padding: "0 16px 8px 16px" }}>
          <Select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            fullWidth
            size="small"
          >
            {uniqueTags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </div>
        <PublishedWorkflowsBody>
          {filteredWorkflows.length > 0 ? (
            filteredWorkflows.map((workflow) => (
              <PublishedWorkflowsItem
                key={workflow.id}
                onClick={() => handleSelectWorkflow(workflow)}
              >
                {workflow.name}
              </PublishedWorkflowsItem>
            ))
          ) : (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <div>No workflows found</div>
              <div style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}>
                {publishedWorkflows.length === 0
                  ? "There are no published workflows available"
                  : "Try searching with a different term"}
              </div>
            </div>
          )}
        </PublishedWorkflowsBody>
        <PublishedWorkflowsFooter>
          <TextField
            label="Search workflows"
            fullWidth
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </PublishedWorkflowsFooter>
      </PopoverContent>
    </Popover>
  );
};
