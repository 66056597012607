import React, { useRef } from "react";
import { Popover } from "@mui/material";
import { PopoverContent } from "../workflowBuilder/style";
import { UploadBtn, FileTypeTxt, FilesDiv, FileCard } from "./style";
import { 
  ToolBarContainer, 
  ToolConfigurationHeader, 
  ToolConfigurationBody, 
  ToolConfigurationFooter, 
  ToolConfigurationCancelButton, 
  ToolConfigurationSaveButton 
} from "../workflowBuilder/style";
import ComputerIcon from "../../assets/computer-icon.svg";
import AvatarDriveFilePicker from "./AvatarDriveFilePicker";
import PdfIcon from "../../assets/pdf-large.svg";
import DocxIcon from "../../assets/docx-large.svg";
import TxtIcon from "../../assets/txt-large.svg";
import closeX from "../../assets/x-close.svg";

const Popovers = ({ 
  anchorEl, 
  onClose, 
  onFileUpload, 
  onRemoveFile, 
  onSave, 
  uploadedFiles, 
  showError,
  selectedTool,
  handleDriveFiles
}) => {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  
  const handleFileChange = (e) => {
    if (e.target.files) {
      onFileUpload(e.target.files);
    }
  };

  const getFileIcon = (fileType) => {
    if (fileType.includes('pdf')) return PdfIcon;
    if (fileType.includes('docx') || fileType.includes('doc')) return DocxIcon;
    return TxtIcon;
  };

  return (
    <Popover
      style={{zIndex: 1000}}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "1rem",
            boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.08)",
          },
        },
      }}
    >
      <PopoverContent>
        <ToolBarContainer>
          <ToolConfigurationHeader>
            <div className="flex flex-col gap-2">
              <div>{selectedTool?.toolName || "Upload Files"}</div>
              <div style={{ fontSize: "12px", fontWeight: "400" }}>
                {"This skill requires a file to run"}
              </div>
            </div>
          </ToolConfigurationHeader>
          <ToolConfigurationBody style={{ gap: "1.25rem", backgroundColor: "#FAFAFA" }}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.75rem", alignItems: "center"}}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.txt"
                multiple
              />
              <UploadBtn onClick={handleUploadClick}>
                <img src={ComputerIcon} alt="computer" />
                Upload from Computer
              </UploadBtn>
              <AvatarDriveFilePicker isInPopover={true} onFilesSelected={handleDriveFiles}/>
              <FileTypeTxt>File Types: PDFs, DOC, DOCX, Text</FileTypeTxt>
              {showError && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  Please upload at least one file
                </div>
              )}
            </div>
            {uploadedFiles.length > 0 && (
              <FilesDiv>
                Files
                <div style={{display: "flex", flexDirection: "column", gap: "0.4rem", fontWeight: "500"}}>
                {uploadedFiles.map((file, index) => (
                  <FileCard key={`${file.name}-${index}`}>
                    <img src={getFileIcon(file.type)} alt={file.type} />
                    {file.name}
                    <img 
                      src={closeX} 
                      alt="remove" 
                      style={{marginLeft: "auto", cursor: "pointer"}}
                      onClick={() => onRemoveFile(file.name)}
                    />
                  </FileCard>
                ))}
                </div>
              </FilesDiv>
            )}
          </ToolConfigurationBody>
          <ToolConfigurationFooter>
            <ToolConfigurationCancelButton variant="outlined" onClick={onClose}>
              Cancel
            </ToolConfigurationCancelButton>
            <ToolConfigurationSaveButton variant="contained" onClick={onSave}>
              Save & Run
            </ToolConfigurationSaveButton>
          </ToolConfigurationFooter>
        </ToolBarContainer>
      </PopoverContent>
    </Popover>
  );
};

export default Popovers;