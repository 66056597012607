import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  LoginBox,
  Title,
  Input,
  InputLabel,
  FormText,
  SignInButton,
  ContentDiv,
  LogoBox,
  PasswordWrapper,
} from "./Style";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../assets/Logo.svg";
import VerificationModal from "./VerificationModal";
import ShowPassword from "../../assets/show-password.svg";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company_name: "",
    password: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userEmail, setUserEmail] = useState(""); // New state for storing user email
  const [showPassword, setShowPassword] = useState(false); 
  const [formErrors, setFormErrors] = useState({}); // Add this new state
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user info is already stored in local storage
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    if (email && password) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Add validation check
    const errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.email.trim()) errors.email = "Email is required";
    if (!formData.password.trim()) errors.password = "Password is required";
    
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setSnackbarMessage("Please fill in all required fields");
      setSnackbarOpen(true);
      return;
    }
    
    setLoading(true);

    try {
      const response = await fetch(
        // "https://api.aiflowpro.io/api/auth/register",
        "https://api.aiflowpro.io/api/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      
      const data = await response.json();
      if (!response.ok) {
        setSnackbarMessage(data.msg || "Network Error");
        setSnackbarOpen(true);
        return
      }

      const secondResponse = await fetch("https://api.emailflowpro.io/api/user/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!secondResponse.ok) {
        throw new Error("Second API call failed");
      }

      const secondData = await secondResponse.json();
      console.log("Second API call successful:", secondData);


      // Assuming the response contains a success message or data
      console.log(data.user.email);
      //localStorage.setItem("email", data.user.email);
      setUserEmail(data.user.email);//set email in state to be used for resend 
      // console.log("Registration successful:", data);
      setSnackbarMessage("Signup Successful");
      setSnackbarOpen(true);
      setModalOpen(true);

    } catch (error) {
      console.log(error);

    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <VerificationModal modalIsOpen={isModalOpen} closeModal={()=>{setModalOpen(false)}}  email={userEmail}/>
      <Container>
        <LoginBox style={{height: '90%'}}>
          <ContentDiv>
            <LogoBox>
              <img src={Logo} alt="logo" />
            </LogoBox>
            <Title>Signup</Title>
            <Form>
              <InputLabel>Name</InputLabel>
              <Input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
                style={{ 
                  borderColor: formErrors.name ? '#ff0000' : '1px solid #ebebfd',
                }}
              />
              <InputLabel style={{ marginTop: "0.655rem" }}>Email</InputLabel>
              <Input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
                style={{ 
                  borderColor: formErrors.email ? '#ff0000' : '1px solid #ebebfd',
                }}
              />
              <InputLabel style={{ marginTop: "0.655rem" }}>
                Company Name
              </InputLabel>
              <Input
                type="text"
                name="company_name"
                placeholder="Enter your company name"
                value={formData.company_name}
                onChange={handleChange}
              />
              <InputLabel style={{ marginTop: "0.655rem" }}>Password</InputLabel>
              <PasswordWrapper>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Enter your password"
                  style={{ 
                    width: "100%",
                    borderColor: formErrors.password ? '#ff0000' : '1px solid #ebebfd',
                  }}
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <img
                  src={ShowPassword}
                  alt="Toggle Password Visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    width: "1.5rem",
                    height: "1.5rem",
                    opacity: "0.7",
                    right: "1rem",
                  }}
                />
              </PasswordWrapper>
              {/* <FormText
                style={{ marginLeft: "auto", marginTop: "0.69rem" }}
                onClick={() => navigate("/forgotpassword")}
                onMouseOver={(e) =>
                  (e.currentTarget.style.textDecoration = "underline")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.textDecoration = "none")
                }
              >
                Forgot password?
              </FormText> */}
              {/* <SignInButton type="submit" onClick={handleSubmit}> */}
              <SignInButton
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={21} color="inherit" />
                ) : (
                  "Sign Up"
                )}
              </SignInButton>
            </Form>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormText>
                Already have an account?
                <span
                  style={{ color: "#0062FF", cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.textDecoration = "underline")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.textDecoration = "none")
                  }
                >
                  Sign In
                </span>
              </FormText>
            </div>
          </ContentDiv>
        </LoginBox>
        <SnackbarAlert
          open={snackbarOpen}
          setOpen={setSnackbarOpen}
          message={snackbarMessage}
          severity={snackbarMessage === "Signup Successful" ? "success" : "error"}
          autoHideDuration={3000} // Adjust as needed
          onClose={handleCloseSnackbar}
        />
      </Container>
    </>
  );
};

export default Signup;
