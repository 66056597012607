import React from "react";
import {
  HeaderDiv,
  HeaderImg,
  HeaderTitle,
  ItemText,
  MainDiv,
  UserAvatars,
  UserInfo,
  Avatar,
  Usage,
  Used,
  CardButton,
  ComingSoon,
} from "./Style";
import DisplayImg from "../../assets/mailing.svg";
import CheckIcon from "../../assets/Check.svg";

export default function GridItems({
  displayImg = DisplayImg,
  headerTitle = "E-mail Automation",
  checkIcon = CheckIcon,
  itemText,
  description = "Connect your email end start with AI automations to optimize your emails. Sort your emails into folders and setup auto replies.",
  btnTxt = "Use This",
  onButtonClick,
  index,
}) {
  return (
    <MainDiv>
      <HeaderDiv>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HeaderImg src={displayImg} />
          <HeaderTitle>{headerTitle}</HeaderTitle>
        </div>
        <img src={checkIcon} alt="check" style={{ marginBottom: "2rem" }} />
      </HeaderDiv>
      <ItemText style={{ marginTop: "1.25rem" }}>{description}</ItemText>

      {/* <UserInfo style={{ width: "90%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <UserAvatars>
            <Avatar src={Avatar1} alt="User 1" />
            <Avatar src={Avatar2} alt="User 2" />
            <Avatar src={Avatar3} alt="User 3" />
            <span>+2</span>
          </UserAvatars>
          <Usage>5 Friends using</Usage>
        </div>

        <Used>10K Used</Used>
      </UserInfo> */}
      {index < 7 ? (
        <CardButton onClick={onButtonClick} className={`grid-item-${index}`}>
          {btnTxt}
        </CardButton>
      ) : (
        <ComingSoon>Coming Soon...</ComingSoon>
      )}
    </MainDiv>
  );
}
