import React, { useState, useEffect } from 'react';
import { SkillsContainer, SkillCard, SkillHeader, SkillIcon, SkillName, RunButton, Tag, SearchContainer } from './style';
import play from "../../assets/play.svg";
import { StyledTextField, StyledSelect, filterTags, tagColors } from "../Constants/Constants";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "../../assets/searchicon.svg";
import InputAdornment from "@mui/material/InputAdornment";
import Popovers from "./Popovers";

const SkillsSection = ({ onRunSkill }) => {
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [workflows, setWorkflows] = useState([]);
  const [userSkills, setUserSkills] = useState([]);
  const [nonSkillTools, setNonSkillTools] = useState([]);
  const [skillTools, setSkillTools] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTool, setSelectedTool] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showError, setShowError] = useState(false);

  // Existing fetch functions remain the same...
  const fetchWorkflows = async () => {
    try {
      const userId = localStorage.getItem('id');
      const response = await fetch(`https://api.aiflowpro.io/api/workflow/getworkflows?user_id=${userId}`);
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setWorkflows(data.workflows);
      }
    } catch (error) {
      console.error('Error fetching workflows:', error);
    }
  };

  const fetchUserSkills = async () => {
    try {
      const userId = localStorage.getItem('id');
      const response = await fetch(`https://api.aiflowpro.io/api/skill/user/${userId}`);
      const data = await response.json();
      
      if (data.success) {
        setUserSkills(data.skills);
      }
    } catch (error) {
      console.error('Error fetching user skills:', error);
    }
  };

  const fetchNonSkillTools = async () => {
    try {
      const response = await fetch('https://api.aiflowpro.io/api/tool/non-skill-tools');
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setNonSkillTools(data.tools);
      }
    } catch (error) {
      console.error('Error fetching non-skill tools:', error);
    }
  };

  const fetchSkillTools = async () => {
    try {
      const response = await fetch('https://api.aiflowpro.io/api/tool/skill-tools');
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setSkillTools(data.tools);
      }
    } catch (error) {
      console.error('Error fetching skill tools:', error);
    }
  };

  useEffect(() => {
    fetchWorkflows();
    fetchUserSkills();
    fetchNonSkillTools();
    fetchSkillTools();
  }, []);

  const handleRunTool = (tool, event) => {
    if (tool.isRequiredDocument) {
      setSelectedTool(tool);
      setAnchorEl(event.currentTarget);
    } else if (onRunSkill) {
      onRunSkill(tool.toolTrigger);
    }
  };

  const handleRunSkillTool = (tool, event) => {
    if (tool.isRequiredDocument) {
      setSelectedTool(tool);
      setAnchorEl(event.currentTarget);
    } else if (onRunSkill) {
      onRunSkill(tool.toolTrigger);
    }
  };

  const handleRunWorkflow = (workflowDescription) => {
    if (onRunSkill) {
      onRunSkill(`${workflowDescription}`);
    }
  };

  const handleRunUserSkill = (skillName) => {
    if (onRunSkill) {
      onRunSkill(`Run the skill ${skillName}`);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedTool(null);
    setUploadedFiles([]);
    setShowError(false);
  };

  const handleFileUpload = (files) => {
    const newFiles = Array.from(files).map((file) => ({
      name: file.name,
      type: file.type,
      file: file,
      isDrive: false,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setShowError(false);
  };

  const handleDriveFiles = async (fileLinks) => {
    const accessToken = localStorage.getItem("access_token");
  
    const newDriveFiles = await Promise.all(
      fileLinks.map(async (fileId) => {
        var fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
        let file;
    
        try {
          // Fetch the file content
          const response = await fetch(fileUrl, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${accessToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error(`Error fetching file: ${response.statusText}`);
          }
  
          // Get the blob data regardless of content type
          const blobData = await response.blob();
          
          // Fetch metadata to get the file name
          const metadataUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=name,mimeType`;
          const metadataResponse = await fetch(metadataUrl, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${accessToken}`,
            },
          });
  
          if (!metadataResponse.ok) {
            throw new Error(`Error fetching metadata: ${metadataResponse.statusText}`);
          }
  
          const metadata = await metadataResponse.json();
          
          // Create a File object from the blob
          file = new File([blobData], metadata.name, {
            type: metadata.mimeType
          });
  
          return {
            name: metadata.name,
            type: metadata.mimeType,
            file: file,
            isDrive: false
          };
  
        } catch (error) {
          console.error("Error:", error.message);
          return null;
        }
      })
    );
  
    // Filter out any failed uploads
    const successfulFiles = newDriveFiles.filter(file => file !== null);
    
    // Add to uploadedFiles state
    if (successfulFiles.length > 0) {
      setUploadedFiles((prevFiles) => [...prevFiles, ...successfulFiles]);
      setShowError(false);
    }
  };
  
  const handleRemoveFile = (fileName) => {
    setUploadedFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const handleSave = () => {
    if (uploadedFiles.length === 0) {
      setShowError(true);
      return;
    }

    if (selectedTool && onRunSkill) {
      onRunSkill(selectedTool.toolTrigger, uploadedFiles);
    }
    handleClosePopover();
  };

  // Existing filter functions remain the same...
  const filteredNonSkillTools = nonSkillTools.filter(item => {
    const nameMatch = item.toolName.toLowerCase().includes(searchQuery.toLowerCase());
    const tagMatch = !sortBy || item.toolTag.toLowerCase() === sortBy.toLowerCase();
    return nameMatch && tagMatch;
  });

  const filteredSkillTools = skillTools.filter(item => {
    const nameMatch = item.toolName.toLowerCase().includes(searchQuery.toLowerCase());
    const tagMatch = !sortBy || item.toolTag.toLowerCase() === sortBy.toLowerCase();
    return nameMatch && tagMatch;
  });

  const filteredWorkflows = workflows.filter(item => {
    const nameMatch = item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const tagMatch = !sortBy || 'workflow'.toLowerCase() === sortBy.toLowerCase();
    return nameMatch && tagMatch;
  });

  const filteredUserSkills = userSkills.filter(item => {
    const nameMatch = item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const tagMatch = !sortBy || 'skill'.toLowerCase() === sortBy.toLowerCase();
    return nameMatch && tagMatch;
  });

  return (
    <div style={{display: "flex", flexDirection: "column", gap: "1.25rem", width: "100%", padding: "1rem"}}>
      <SearchContainer>
        <StyledTextField
          placeholder="Search Skills"
          variant="outlined"
          fullWidth
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} alt="search" />
              </InputAdornment>
            ),
          }}
        />
        <StyledSelect
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          displayEmpty
          size="small"
          style={{ minWidth: 120 }}
        >
          {filterTags.map((tag) => (
            <MenuItem key={tag.value} value={tag.value}>
              {tag.label}
            </MenuItem>
          ))}
        </StyledSelect> 
      </SearchContainer>
      <SkillsContainer>
        {filteredNonSkillTools.map((tool) => (
          <SkillCard key={tool._id}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.62rem"}}>
              <SkillHeader>
                <SkillIcon>
                  <img src={play} alt="play" />
                </SkillIcon>
                <SkillName>{tool.toolName}</SkillName>
              </SkillHeader>
              <Tag style={{
                background: tagColors[tool.toolTag.toLowerCase()]?.bg || 'rgba(26, 116, 219, 0.2)',
                color: tagColors[tool.toolTag.toLowerCase()]?.text || '#1a74db'
              }}>
                {tool.toolTag}
              </Tag>
            </div>
            <SkillHeader style={{fontSize: "0.875rem", fontWeight: "500"}}>
              {tool.toolDescription}
            </SkillHeader>
            <RunButton onClick={(event) => handleRunTool(tool, event)}>
              Run
            </RunButton>
          </SkillCard>
        ))}
        {filteredSkillTools.map((tool) => (
          <SkillCard key={tool._id}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.62rem"}}>
              <SkillHeader>
                <SkillIcon>
                  <img src={play} alt="play" />
                </SkillIcon>
                <SkillName>{tool.toolName}</SkillName>
              </SkillHeader>
              <Tag style={{
                background: tagColors[tool.toolTag.toLowerCase()]?.bg || 'rgba(26, 116, 219, 0.2)',
                color: tagColors[tool.toolTag.toLowerCase()]?.text || '#1a74db'
              }}>
                {tool.toolTag}
              </Tag>
            </div>
            <SkillHeader style={{fontSize: "0.875rem", fontWeight: "500"}}>
              {tool.toolDescription}
            </SkillHeader>
            <RunButton onClick={(event) => handleRunSkillTool(tool, event)}>
              Run
            </RunButton>
          </SkillCard>
        ))}
        {/* Existing workflow and user skills mapping remains the same... */}
        {filteredWorkflows.map((workflow) => (
          <SkillCard key={workflow._id}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.62rem"}}>
              <SkillHeader>
                <SkillIcon>
                  <img src={play} alt="play" />
                </SkillIcon>
                <SkillName>{workflow.name}</SkillName>
              </SkillHeader>
              <Tag style={{ background: tagColors['workflow']?.bg || 'rgba(26, 116, 219, 0.2)', color: tagColors['workflow']?.text || '#1a74db'}}>
                workflow
              </Tag>
            </div>
            <SkillHeader style={{fontSize: "0.875rem", fontWeight: "500"}}>
              {workflow.description}
            </SkillHeader>
            <RunButton onClick={() => handleRunWorkflow(workflow.description)}>
              Run
            </RunButton>
          </SkillCard>
        ))}
        {filteredUserSkills.map((skill) => (
          <SkillCard key={skill._id}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.62rem"}}>
              <SkillHeader>
                <SkillIcon>
                  <img src={play} alt="play" />
                </SkillIcon>
                <SkillName>{skill.name}</SkillName>
              </SkillHeader>
              <Tag style={{ background: tagColors['skill']?.bg || 'rgba(26, 116, 219, 0.2)', color: tagColors['skill']?.text || '#1a74db'}}>
                Skill
              </Tag>
            </div>
            <SkillHeader style={{fontSize: "0.875rem", fontWeight: "500"}}>
              {skill.objectives}
            </SkillHeader>
            <RunButton onClick={() => handleRunUserSkill(skill.name)}>
              Run
            </RunButton>
          </SkillCard>
        ))}
      </SkillsContainer>
      <Popovers 
        anchorEl={anchorEl} 
        onClose={handleClosePopover}
        onFileUpload={handleFileUpload}
        onRemoveFile={handleRemoveFile}
        onSave={handleSave}
        uploadedFiles={uploadedFiles}
        showError={showError}
        selectedTool={selectedTool}
        handleDriveFiles={handleDriveFiles}
      />
    </div>
  );
};

export default SkillsSection;