import React, { useRef, useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import AvatarChatBg from "../../assets/avatar_chat_bg.png";
import PanZoom from "react-easy-panzoom";
import { FaPlus, FaMinus, FaExpand } from "react-icons/fa";
import {
  Container,
  ContainerContent,
  Header,
  Button,
  PanZoomContainer,
  PanZoomControls,
  HeaderLeft,
  HeaderMenu,
  BackIcon,
  WorkflowTestIcon,
  WorkflowExploreIcon,
} from "./style";
import BackIconImg from "../../assets/back-white-icon.svg";
import WorkflowName from "./workflowName";
import Tooltip from "@mui/material/Tooltip";
import Node from "./node";
import SnackbarAlert from "../Alerts/SnackbarAlert";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "@mui/material/CircularProgress";
import TestTubeIcon from "../../assets/workflows/test-tube-icon.svg";
import ChatTest from "./chatTest";
import ExploreIcon from "../../assets/workflows/expolore-icon.svg";
import { PublishedWorkflowsPopovers } from "./publishedWorkflowsPopovers";

const WorkflowBuilder = () => {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const panZoomRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [treeData, setTreeData] = useState([
    {
      id: uuidv4(),
      name: "Trigger",
      img: "TriggerIcon",
      children: [],
      formData: {},
    },
  ]);
  const [workflowSettings, setWorkflowSettings] = useState({
    name: "",
    description: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [workflow, setWorkflow] = useState();
  const [reRender, setReRender] = useState(true);
  const [newNodeId, setNewNodeId] = useState(null);
  const [allTools, setAllTools] = useState([]);
  const [workflow_id, setWorkflow_id] = useState(
    new URLSearchParams(window.location.search).get("workflow_id")
  );
  const [anchorElChat, setAnchorElChat] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingAndTesting, setIsSavingAndTesting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [publishedWorkflows, setPublishedWorkflows] = useState([]);
  const [anchorElPublishedWorkflows, setAnchorElPublishedWorkflows] =
    useState(null);

  useEffect(() => {
    if (workflow_id) {
      console.log("workflow_id : ", workflow_id);
      fetchWorkflow(workflow_id);
    }
  }, [workflow_id]);

  const handleZoomIn = () => {
    if (zoomLevel < 3 && panZoomRef.current) {
      panZoomRef.current.zoomIn();
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.5 && panZoomRef.current) {
      panZoomRef.current.zoomOut();
    }
  };

  const handleReset = () => {
    if (panZoomRef.current) {
      panZoomRef.current.autoCenter();
    }
  };

  useEffect(() => {
    if (
      treeData?.length > 0 &&
      treeData[0]?.children?.[0]?.children?.[0]?.children?.length > 0 &&
      panZoomRef.current
    ) {
      panZoomRef.current.autoCenter();
    }
    if (!reRender) {
      setReRender(true);
    }
  }, [treeData, reRender]);

  const reRenderNodes = (action) => {
    if (treeData?.[0]?.children?.length > 0 && action === "Delete") {
      setReRender(false);
    }
  };

  const handleBack = () => {
    navigate("/workflows");
  };

  const checkEndNodes = (node) => {
    if (!node?.children?.length) {
      return node?.name === "End";
    }
    return node.children.every(checkEndNodes);
  };

  const createWorkflow = async (
    saveAndTestTrigger = false,
    saveAndTestEvent = null
  ) => {
    console.log("treeData : ", treeData);
    if (!workflowSettings.name || !workflowSettings.description) {
      handleSnackbar("error", "Please fill all the fields");
      return;
    }
    if (!treeData?.[0]?.id) {
      handleSnackbar("error", "Please add at least one node");
      return;
    }

    // Check if all branches end with End node
    if (!checkEndNodes(treeData[0])) {
      handleSnackbar(
        "error",
        "All workflow branches must end with an End node"
      );
      return;
    }

    try {
      setIsSavingAndTesting(saveAndTestTrigger);
      setIsSaving(true);
      const payload = {
        user_id: localStorage.getItem("id"),
        name: workflowSettings.name,
        description: workflowSettings.description,
        json: JSON.stringify(treeData),
        status: true,
      };
      if (workflow) {
        updateWorkflow();
        return;
      }
      const response = await fetch(
        "https://api.aiflowpro.io/api/workflow/createworkflow",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      console.log("response : ", data);
      if (data.responseCode === 201) {
        handleSnackbar("success", data.message);
        if (saveAndTestTrigger) {
          setWorkflow(data.workflow);
          window.history.replaceState(
            null,
            "",
            `?workflow_id=${data.workflow._id}`
          );
          setTimeout(() => {
            setAnchorElChat(saveAndTestEvent);
          }, 100);
          return;
        } else {
          navigate("/workflows");
        }
      } else {
        handleSnackbar("error", data.message);
      }
    } catch (error) {
      console.error("Error creating workflow:", error);
    } finally {
      setIsSavingAndTesting(false);
      setIsSaving(false);
    }
  };

  const updateWorkflow = async () => {
    try {
      setIsSaving(true);
      const payload = {
        name: workflowSettings.name,
        description: workflowSettings.description,
        json: JSON.stringify(treeData),
        status: workflow?.status,
      };
      const response = await fetch(
        "https://api.aiflowpro.io/api/workflow/update/" + workflow?._id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        handleSnackbar("success", data.message);
        navigate("/workflows");
      } else {
        handleSnackbar("error", data.message);
      }
    } catch (error) {
      console.error("Error updating workflow:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const fetchWorkflow = async (workflow_id) => {
    try {
      setIsLoading(true);
      setTreeData([]);
      const response = await fetch(
        "https://api.aiflowpro.io/api/workflow/" + workflow_id,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        handleSnackbar("success", "Workflow fetched successfully");
        setTreeData(JSON.parse(data.workflow.json));
        setWorkflowSettings({
          name: data.workflow.name,
          description: data.workflow.description,
        });
        setTimeout(() => {
          handleReset();
        }, 1000);
        setWorkflow(data.workflow);
      } else {
        handleSnackbar("error", "Failed to fetch workflows");
      }
    } catch (error) {
      console.log("error : ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllTools = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://api.aiflowpro.io/api/tool/get-all-tools",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        setAllTools(data.tools);
      }
    } catch (error) {
      console.log("fetchAllTools error : ", error);
    }
  };

  useEffect(() => {
    fetchAllTools();
  }, []);

  const handleOpenChat = (event) => {
    if (!workflow) {
      createWorkflow(true, event.currentTarget);
      return;
    }
    setAnchorElChat(event.currentTarget);
  };

  const fetchPublisWorkflow = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://api.aiflowpro.io/api/auth/get-published-workflows",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        setPublishedWorkflows(data.workflows);
      }
    } catch (error) {
      console.log("fetchPublisWorkflow error:", error);
    }
  };

  useEffect(() => {
    fetchPublisWorkflow();
  }, []);

  const handleOpenPublishedWorkflowsPopover = (event) => {
    setAnchorElPublishedWorkflows(event.currentTarget);
  };

  const handleClosePublishedWorkflowsPopover = (data) => {
    setAnchorElPublishedWorkflows(null);
    if (data.type === "select") {
      setTreeData([]);
      setTreeData(JSON.parse(data.workflow.json));
    }
  };

  return (
    <Container
      isOpen={isSidebarOpen}
      style={{
        marginRight: "0rem",
        backgroundImage: `url(${AvatarChatBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div style={{ height: "100%", padding: "2rem", boxSizing: "border-box" }}>
        <ContainerContent>
          <Header>
            <HeaderLeft>
              <BackIcon src={BackIconImg} alt="Back" onClick={handleBack} />
              <WorkflowName
                workflowSettings={workflowSettings}
                onSave={setWorkflowSettings}
              />
            </HeaderLeft>
            <HeaderMenu>
              <Button onClick={() => createWorkflow(false)} disabled={isSaving}>
                {isSaving ? (
                  <CircularProgress size={20} />
                ) : (
                  <>{workflow ? "Update" : "Save"}</>
                )}
              </Button>
              <WorkflowTestIcon
                onClick={handleOpenChat}
                disabled={isSavingAndTesting}
              >
                {isSavingAndTesting ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    <img src={TestTubeIcon} alt="Test" />
                    Save and Test
                  </>
                )}
              </WorkflowTestIcon>
            </HeaderMenu>
          </Header>
          <PanZoom
            ref={panZoomRef}
            zoom={zoomLevel}
            minZoom={0.2}
            maxZoom={5}
            disableDoubleClickZoom={true}
            disableKeyInteraction={true}
            style={{
              width: "100%",
              height: "calc(100% - 68px)",
              overflow: "hidden",
              cursor: "move",
              borderRadius: "0px 0px 31px 31px",
            }}
          >
            <PanZoomContainer
              style={{
                marginTop:
                  !workflow_id || treeData[0]?.children?.length === 0
                    ? "32vh"
                    : "0",
              }}
            >
              {reRender && !isLoading ? (
                treeData.map((node) => (
                  <Node
                    key={node.id}
                    node={node}
                    treeData={treeData}
                    setTreeData={setTreeData}
                    onDelete={reRenderNodes}
                    newNodeId={newNodeId}
                    setNewNodeId={setNewNodeId}
                    allTools={allTools}
                  />
                ))
              ) : (
                <div style={{ marginLeft: "40vw", marginTop: "2vh" }}>
                  <CircularProgress style={{ color: "#1a74db" }} size={40} />
                </div>
              )}
            </PanZoomContainer>
          </PanZoom>
          <PanZoomControls>
            <Tooltip title="Zoom In" placement="top">
              <Button
                style={{ borderRadius: "31px 0px 0px 31px" }}
                onClick={handleZoomIn}
              >
                <FaPlus />
              </Button>
            </Tooltip>
            <Tooltip title="Fit to Screen" placement="top">
              <Button style={{ borderRadius: "0px" }} onClick={handleReset}>
                <FaExpand />
              </Button>
            </Tooltip>
            <Tooltip title="Zoom Out" placement="top">
              <Button
                style={{ borderRadius: "0px 31px 31px 0px" }}
                onClick={handleZoomOut}
              >
                <FaMinus />
              </Button>
            </Tooltip>
          </PanZoomControls>
          {/* {workflow_id && (
            <WorkflowTestIcon onClick={handleOpenChat}>
              <img src={TestTubeIcon} alt="Test" />
              Test
            </WorkflowTestIcon>
          )} */}
          <WorkflowExploreIcon onClick={handleOpenPublishedWorkflowsPopover}>
            <img src={ExploreIcon} alt="Explore" />
            Explore
          </WorkflowExploreIcon>
        </ContainerContent>
      </div>
      <SnackbarAlert
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <ChatTest
        anchorElChat={anchorElChat}
        onCloseChat={() => setAnchorElChat(null)}
        workflow={workflow}
      />
      <PublishedWorkflowsPopovers
        anchorElPublishedWorkflows={anchorElPublishedWorkflows}
        onClosePublishedWorkflowsPopover={handleClosePublishedWorkflowsPopover}
        publishedWorkflows={publishedWorkflows}
      />
    </Container>
  );
};

export default WorkflowBuilder;
