import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IntroductionContainer,
  ContentWrapper,
  TextSection,
  Heading,
  IntroTxt,
  NextButton,
  ImageSection,
  LaptopImage,
  LogoContainer
} from './style';
import { CircularProgress, Select, MenuItem, TextField, FormControl, FormHelperText } from "@mui/material";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import LaptopImg from "../../assets/introduction.jpg";
import LogoImg from "../../assets/Logo.svg";

const Introduction = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [profession, setProfession] = useState('');
  const [customProfession, setCustomProfession] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  
  const handleNextClick = () => {
    if (step === 1) {
      setStep(2);
    } else {
      // For step 2, validate profession selection
      if (!profession) {
        setError(true);
        return;
      }
      
      // Check if custom profession is selected but no value is entered
      if (profession === 'Custom' && !customProfession) {
        setError(true);
        return;
      }
      
      // For step 2, handle the API call
      const selectedProfession = profession === 'Custom' ? customProfession : profession;
      const userId = localStorage.getItem('id');
      
      if (!userId) {
        console.error("Missing userId");
        return;
      }
      
      setLoading(true);
      
      fetch('https://api.aiflowpro.io/api/auth/update-profession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          profession: selectedProfession
        }),
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data.message);
          navigate('/avatar-selection');
        })
        .catch(error => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  
  const handleSkip = () => {
    navigate('/avatar-selection');
  };
  
  const handleProfessionChange = (event) => {
    setProfession(event.target.value);
    setError(false); // Clear error when user makes a selection
  };
  
  const handleCustomProfessionChange = (event) => {
    setCustomProfession(event.target.value);
    if (event.target.value) {
      setError(false); // Clear error when user enters text
    }
  };
  
  const renderStep1 = () => {
    return (
      <>
        <Heading>Welcome to AI Flow Pro, your personal assistant!</Heading>
        <IntroTxt>
          Your ultimate personal assistant! Simplify your daily routine by setting up your assistant to handle repetitive tasks with ease. From sorting emails and tracking to-dos to actively participating in meetings and managing a wide range of tasks, AI Flow Pro has you covered. You can even create custom tasks tailored to your needs. Plus, with an integrated calendar, you'll have complete control and organization at your fingertips.
        </IntroTxt>
        <NextButton onClick={handleNextClick} style={{marginTop: "3rem", width: "max-content"}}>
          Next
        </NextButton>
      </>
    );
  };
  
  const renderStep2 = () => {
    return (
      <>
        <Heading>Select your Profession</Heading>
        <IntroTxt>
          Please select your profession to tailor your experience
        </IntroTxt>
        
        <FormControl 
          fullWidth 
          style={{ marginTop: "2rem", width: "80%"}}
          error={error}
        >
          <Select
            value={profession}
            onChange={handleProfessionChange}
            displayEmpty
            style={{ marginBottom: "1rem" }}
            sx={{
              borderRadius: "0.5rem",
              borderColor: error ? "red" : undefined
            }}
          >
            <MenuItem value="" disabled>Select your profession</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
            <MenuItem value="Personal Assistant">Personal Assistant</MenuItem>
            <MenuItem value="Marketing">Marketing</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
          {error && !profession && (
            <FormHelperText error>Please select your profession</FormHelperText>
          )}
          
          {profession === 'Custom' && (
            <TextField
              fullWidth
              placeholder="Enter your profession"
              value={customProfession}
              onChange={handleCustomProfessionChange}
              style={{ marginTop: "1rem" }}
              error={error && !customProfession}
              helperText={error && !customProfession ? "Please enter your profession" : ""}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "0.5rem"
                },
              }}
            />
          )}
        </FormControl>
        
        <div style={{ display: "flex", marginTop: "3rem" }}>
          <NextButton onClick={handleSkip} style={{ width: "max-content", color: "#696974", background: "#FFF"}}>
            Skip
          </NextButton>
          <NextButton 
            onClick={handleNextClick} 
            style={{ width: "max-content"}}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
          </NextButton>
        </div>
      </>
    );
  };
  
  // Add styles for transitions
  const transitionStyles = `
    .fade-enter {
      opacity: 0;
      transform: translateY(20px);
    }
    .fade-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 400ms, transform 400ms;
    }
    .fade-exit {
      opacity: 1;
      transform: translateY(0);
    }
    .fade-exit-active {
      opacity: 0;
      transform: translateY(-20px);
      transition: opacity 400ms, transform 400ms;
    }
  `;
  
  return (
    <IntroductionContainer>
      <style>{transitionStyles}</style>
      <LogoContainer>
        <img src={LogoImg} alt="AI Flow Pro Logo" />
      </LogoContainer>
      <ContentWrapper>
        <TextSection>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={step}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div>
                {step === 1 ? renderStep1() : renderStep2()}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </TextSection>
        <ImageSection>
          <img src={LaptopImg} alt="AI Flow PRO Dashboard" />
        </ImageSection>
      </ContentWrapper>
    </IntroductionContainer>
  );
};

export default Introduction;