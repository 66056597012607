import React, {useState, useEffect} from 'react';
import axios from "axios";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CircularProgress from "@mui/material/CircularProgress"; 
import SnackbarAlert from '../Alerts/SnackbarAlert';
import { ModalOverlay, Content, Header, CardDiv, CardInfo, AddButton } from "./Style";
import CloseX from "../../assets/x-close.svg";
import VisaCard from "../../assets/visaCard-icon.svg";
import MasterCard from "../../assets/MasterCard.svg";
import Divider from "../../assets/card-divider.svg";
import { CardImgs } from "../../Components/Constants/Constants";
import { useMediaQuery } from '@mui/material';

const stripePromise = loadStripe(
    "pk_live_51NgPyEJ41ABvy6vn5DbNZUb6k9qYNS6dgCo820lzfqzrOWNqSliTfzoWc96VPvSYVp1s4XQVYYD6KTxhsKBijxqy00ly2MqgAr"
  );


const AddCardWrapper = ({onClose, card, onUpdate}) => {
  const [key, setKey] = useState(0);
  return (
    <Elements stripe={stripePromise} key={key}>
      <AddCardModal setKey={setKey} onClose={onClose} card={card} onUpdate={onUpdate}/>
    </Elements>
  );
};

const AddCardModal = ({setKey, onClose, card, onUpdate}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [cardBrandImg, setCardBrandImg] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [cardInfo, setCardInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);

  //Responsive Styling
  const isLargeScreen = useMediaQuery('(max-width: 1470px)');
  const isMediumScreen = useMediaQuery('(max-width: 1100px)');
  const isSmallScreen = useMediaQuery('(max-width: 700px)');


  useEffect(() => {
    if (card) {
      setCardInfo(card);
      const cardBrand = card?.cardBrand;
      setCardBrandImg(CardImgs[cardBrand]);
    }
  }, [card]);

  const handlePayment = async () => {
    setLoading(true);
    const stripeCustomerId = localStorage.getItem("stripeCustomerId");
    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      console.error("Error creating token:", error);
      setSnackbarMessage("Enter Correct Information");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
      return;
    } else {

      try {
        const response = await axios.post(
          "https://api.aiflowpro.io/api/auth/add-card",
          {
            stripeToken: token.id,
            customerId: stripeCustomerId,
          },
        );
        if (response.data.responseCode === 2000) {
          setSnackbarMessage("Card Added Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setCardInfo(response.data.response);
          setKey((prevKey) => prevKey + 1); 
          onUpdate();
        } else {
          setSnackbarMessage("Failed to add card");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage("Failed to add card");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
      setTimeout(() => {
        setSnackbarOpen(false);
        onClose();
      }, 1000);
    }
  };

  return (
    <ModalOverlay>
      <Content style={{position: "relative", height: "30rem", width: isSmallScreen? "70%" : isMediumScreen ? "50%" : isLargeScreen ? "40%" : "30%"}}>
        <Header>Enter Card Details</Header>
        <img src={CloseX} alt="close" style={{position: "absolute", right: "1rem", cursor: "pointer"}} onClick={onClose}/>
        <div
        style={{
          width: "90%", 
          margin: "auto",
          borderRadius: "20px",
          boxShadow: "0px 10px 15px 0px rgba(209, 213, 223, 0.5)",
          padding: "32px 28px",
          inset: "40px",
          zIndex: 1001,
        }}
      >
        <div style={{ display: "flex" }}>
          <Header>{editMode ? "Enter Card Details" : "Card Details"}</Header>
          <div style={{ marginLeft: "auto" }}>
            <img src={VisaCard} alt="visa" style={{ height: "30px" }} />
            <img src={MasterCard} alt="Master" style={{ height: "30px" }} />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <img src={Divider} alt="divider" style={{ width: "100%" }} />
        </div>
        {cardInfo && !editMode ? (
          <CardDiv>
            <img
              src={cardBrandImg}
              alt={cardInfo?.cardBrand}
              style={{ width: "4.625rem", height: "2.8125rem" }}
            />
            <CardInfo>**** **** **** {cardInfo?.cardLastFourDigits}</CardInfo>
            <CardInfo style={{ marginLeft: "auto" }}>
              {cardInfo?.cardExpiryMonth} / {cardInfo?.cardExpiryYear}
            </CardInfo>
          </CardDiv>
        ) : (
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "27px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />            
        )}
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            gap: "0.5rem",
            justifyContent: "end",
            marginTop: "2rem",
          }}
        >
        {(!cardInfo || editMode) && (
          <AddButton onClick={handlePayment}>
            {!loading ? (
              "Add Card"
            ) : (
              <CircularProgress size={21} color="inherit" />
            )}
            </AddButton>
          )}
          {cardInfo && (
            <AddButton onClick={() => setEditMode(!editMode)}>
              {editMode ? "Cancel" : "Edit"}
            </AddButton>
          )}
        </div>        
      </div>
      </Content>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </ModalOverlay>
  )
}

export default AddCardWrapper;
