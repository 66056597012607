import React from "react";
import { useNavigate } from "react-router-dom";

import { Icon, PageTitle, PageDiv, Message, Button } from "./Style";
import CrossIcon from "../../assets/cross-icon.svg"; // Adjust the path as necessary

const EmailConfirmationFailed = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/login");
  };

  return (
    <PageDiv>
      <Icon src={CrossIcon} alt="Error Icon" />
      <PageTitle>Email Verification Failed</PageTitle>
      <Message>Either link expired or email already verified</Message>
      <Button onClick={handleContinue} style={{ marginTop: "1rem" }}>
        Continue to signin
      </Button>
    </PageDiv>
  );
};

export default EmailConfirmationFailed;