import {
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Divider, Menu } from "antd";
import React from "react";

const DropdownMenu = ({ onDelete, onEdit, skill, onRunSkill }) => (
  <Menu>
    <Menu.Item
      icon={<PlayCircleOutlined />}
      onClick={() => {
        onRunSkill(skill);
      }}
    >
      Run Skill
    </Menu.Item>
    <Divider style={{ margin: "4px 0" }} />

    <Menu.Item
      icon={<EditOutlined />}
      onClick={() => {
        onEdit(skill);
      }}
    >
      View & Edit
    </Menu.Item>
    <Divider style={{ margin: "4px 0" }} />

    {/* <Menu.Item icon={<HistoryOutlined />} onClick={() => {}}>
      History
    </Menu.Item>
    <Divider style={{ margin: "4px 0" }} /> */}

    <Menu.Item
      icon={<DeleteOutlined />}
      onClick={() => {
        onDelete(skill.key);
      }}
    >
      Delete
    </Menu.Item>
  </Menu>
);

export default DropdownMenu;
