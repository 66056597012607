import styled, { keyframes } from "styled-components";
import { Box } from "@mui/material";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  background-color: #fdfdfd;
  // min-height: 47.25rem;
  margin-right: 0rem;
  margin-left: ${({ isOpen }) => (isOpen ? "17.50rem" : "3.8rem")};
  @media (max-height: 850px) {
    margin-left: 0rem;
  }
`;

export const ContainerContent = styled.div`
  border-radius: 31px;
  border: 1px solid #fff;
  backdrop-filter: blur(40px);
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #fff;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const BackIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Button = styled.button`
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 31px;
  padding: 0.75rem 1rem;
  cursor: pointer;

  color: #3e473e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: -0.16px;

  transition: all 0.3s ease;
  &:hover {
    background-color: transparent;
    color: #fff;
  }
`;

export const PanZoomContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const PanZoomControls = styled.div`
  position: absolute;
  left: 12px;
  bottom: 12px;
`;

export const ButtonName = styled(Button)`
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 31px;
  padding: 0.75rem 1rem;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: -0.16px;

  transition: all 0.3s ease;
  &:hover {
    color: #000;
    border: 1px solid #000;
  }
`;

export const Footer = styled.div`
  min-width: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ isActive }) =>
    isActive ? "rgba(26, 116, 219, 0.2)" : "#fff"};
  gap: 10px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid rgba(212, 224, 223, 0.3);
  transition: all 0.3s ease;
  &:hover {
    background: rgba(26, 116, 219, 0.2);
  }

  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;

export const FooterMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 100px;
  background: #111224;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const NodeContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  border-radius: 16px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);

  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  margin: 0px 8px;

  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  text-align: center;
`;

export const AddNodeContainer = styled.div`
  background: #1a74db;
  border-radius: 16px;
  padding: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);

  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  margin: 0px 8px;
`;

export const HorizontalLine = styled.div`
  width: 100px;
  height: 3px;
  border-top: 3px dashed #fff;
`;

export const VerticalLine = styled.div`
  width: 3px;
  height: 100%;
  border-left: 3px dashed #fff;
`;

export const YesNoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const YesNoText = styled.p`
  margin-left: -30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 0.5rem 1rem;
  color: var(--Dark-Blue-800, #3a3f52);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

// Popover Styling to be used in workflowBuilder/Popovers.jsx
export const PopoverContent = styled(Box)`
  // padding: 1.25rem 1rem;
  width: 356px;
  background: #fff;
  border-radius: 1rem;
`;

export const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0.44rem;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 111.111% */
  border-radius: 1rem;
  background: #1a74db;
  cursor: pointer;
  &:hover {
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const PopoverHdr = styled(Box)`
  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
`;

export const PopoverMainHdr = styled(PopoverHdr)`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  img {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    filter: invert(1);
    transition: all 0.3s ease;
    &:hover {
      filter: invert(0);
    }
  }
`;

import { Tabs as MuiTabs, Tab as MuiTab } from "@mui/material";

export const Tabs = styled(MuiTabs)`
  margin-bottom: 8px;
  border-radius: 16px;
  background-color: #f5f5f5;

  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
  }
`;

export const Tab = styled(MuiTab)`
  padding: 8px 16px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.14px;
  text-transform: capitalize !important;
  color: #3a3f52;

  transition: all 0.3s ease;
  &:hover {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #1a74db;
    }
  }
`;

//toolBar Styling to be used in workflowBuilder/toolBar.jsx
export const ToolBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ToolBarHeader = styled.div`
  color: #3a3f52;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
  padding: 16px;
  padding-bottom: 8px;
`;

export const ToolBarBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  max-height: 350px;
`;

export const ToolBarItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 16px;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: rgba(26, 116, 219, 0.2);
  }

  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;

export const NoActionsFound = styled.div`
  padding: 6px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 150px;

  color: #000;
  font-family: Inter;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;

export const ToolBarFooter = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  background: #fff;
  box-shadow: 0px -1px 3px 0px rgba(16, 24, 40, 0.1),
    0px -1px 2px 0px rgba(16, 24, 40, 0.06);
`;

export const ToolBarTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  max-height: 462px;
  overflow-y: auto;
  overflow-wrap: break-word;
  background-color: #f7f7f7;
`;

export const ToolBarTag = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ bgColor }) => bgColor};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border-color: #001010;
  }
  &.active {
    border-color: #001010;
  }
`;

// Tool Configuration Styling to be used in workflowBuilder/toolConfiguration.jsx
export const ToolConfigurationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background: #1a74db;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  img {
    cursor: pointer;
    filter: invert(1);
    transition: all 0.3s ease;
    &:hover {
      filter: invert(0);
    }
  }
`;

export const ToolConfigurationBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  max-height: 400px;
  padding: 16px;
`;

export const ToolConfigurationFooter = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  align-self: stretch;

  background: #fff;
  box-shadow: 0px -1px 3px 0px rgba(16, 24, 40, 0.1);
`;

import { Button as MuiButton } from "@mui/material";

export const ToolConfigurationSaveButton = styled(MuiButton)`
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px !important;
  background: #1a74db;

  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
`;

export const ToolConfigurationCancelButton = styled(MuiButton)`
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px !important;
  border: 1px solid #3e473e !important;

  color: #3e473e !important;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
`;

//suggestion card
export const SuggestionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
`;

export const SuggestionCard = styled.div`
  border-radius: 8px;
  background: rgba(212, 224, 223, 0.3);
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(212, 224, 223, 0.5);
  }

  color: var(--Colors-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

//workflow chart styling
export const WorkflowTestIcon = styled.div`
  // position: absolute;
  // right: 12px;
  // top: 79px;

  min-width: 130px;
  border-radius: 45px;
  background: #f43;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  display: flex;
  align-items: center;
  gap: 4px;

  img {
    width: 20px;
    height: 20px;
  }

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const UserMessage = styled.div`
  display: flex;
  padding: 10px 14px;
  align-items: flex-start;
  align-self: stretch;

  max-width: 90%;
  width: fit-content;
  border-radius: 8px;
  background: #1a74db;

  color: var(--Brand-White, #fff);

  /* Base/Normal */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

export const AIMessage = styled(UserMessage)`
  display: flex;
  padding: 10px 14px;
  align-items: flex-start;

  max-width: 90%;
  width: fit-content;
  border-radius: 8px;
  background: rgba(212, 224, 223, 0.3);

  color: var(--Colors-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));

  /* Base/Normal */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

//workflow explore icon styling to be used in workflowBuilder/index.jsx
export const WorkflowExploreIcon = styled.div`
  position: absolute;
  right: 12px;
  bottom: 12px;

  border-radius: 45px;
  background: #000;
  padding: 24px 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  display: flex;
  align-items: center;
  gap: 4px;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PublishedWorkflowsHeader = styled.div`
  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  padding: 16px;
`;

export const PublishedWorkflowsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  height: 330px;
`;

export const PublishedWorkflowsItem = styled.div`
  padding: 6px 16px;
  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  background: #fff;
  transition: all 0.3s ease;
  &:hover {
    color: var(--Blue-900, #174091);
    background: var(--Blue-50, #eeeff7);
  }
`;

export const PublishedWorkflowsFooter = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  align-self: stretch;
`;
