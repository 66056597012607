import React, { useState, useRef } from "react";
import { Flex, Button, Upload, Typography } from "antd";
import {
  ArrowUpOutlined,
  FileOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Attachment from "../../assets/paperclip.svg";
import Popover from "@mui/material/Popover";
import { UploadDiv } from "../Avatar/style";
import ComputerIcon from "../../assets/computer-icon.svg";
import AvatarDriveFilePicker from "../Avatar/AvatarDriveFilePicker";

const ChatInput = ({
  message,
  setMessage,
  handleSend,
  file,
  handleFileChange,
  FileError,
  handleRemoveFile,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const open = Boolean(anchorEl);
  const pop_id = open ? "file-upload-popover" : undefined;

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileInfo = {
        file: selectedFile,
        fileList: [selectedFile],
      };
      handleFileChange(fileInfo);
      handleClosePopover();
    }
  };

  const handleDriveFiles = async (fileLinks) => {
    handleClosePopover();
    
    const accessToken = localStorage.getItem("access_token");
    
    try {
      // Show loading state if needed
      // setIsDriveFilesLoading(true);
      
      // Process just the first file from fileLinks
      const fileId = fileLinks[0]; // Take only the first file
      
      if (!fileId) {
        return;
      }
      
      // Fetch file content
      const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
      const response = await fetch(fileUrl, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      
      if (!response.ok) {
        throw new Error(`Error fetching file: ${response.statusText}`);
      }
      
      // Get the blob data
      const blobData = await response.blob();
      
      // Fetch metadata to get file name and type
      const metadataUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=name,mimeType`;
      const metadataResponse = await fetch(metadataUrl, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      
      if (!metadataResponse.ok) {
        throw new Error(`Error fetching metadata: ${metadataResponse.statusText}`);
      }
      
      const metadata = await metadataResponse.json();
      
      // Create a File object from the blob
      const selectedFile = new File([blobData], metadata.name, {
        type: metadata.mimeType
      });
      
      // Format the file info the same way handleFileInputChange does
      const fileInfo = {
        file: selectedFile,
        fileList: [selectedFile],
      };
      
      // Call handleFileChange with this file info
      handleFileChange(fileInfo);
      
    } catch (error) {
      console.error("Error in handleDriveFiles:", error.message);
    } finally {
      // setIsDriveFilesLoading(false);
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (message.trim() || file) {
        handleSend();
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      const fileInfo = {
        file: droppedFile,
        fileList: [droppedFile],
      };
      handleFileChange(fileInfo);
    }
  };

  return (
    <Flex
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        width: "95%",
        border: "1px solid #E0E0E0",
        borderRadius: "16px",
        padding: "8px 16px",
        boxShadow:
          "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
        flexDirection: "column",
        gap: "8px",
        backgroundColor: isDragging ? "#E6F7FF" : "#FFFFFF",
      }}
    >
      {isDragging && (
        <Typography.Text style={{ textAlign: "center", color: "#1890FF" }}>
          Drop your files here
        </Typography.Text>
      )}
      <Flex>
        <input
          type="file"
          id="fileInput"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".pdf"
          onChange={handleFileInputChange}
        />
        <Button
          icon={
            <img
              src={Attachment}
              alt="attachment"
              style={{ width: "16px", height: "16px" }}
            />
          }
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={handleOpenPopover}
          aria-describedby={pop_id}
        />
        <Popover
          id={pop_id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#f1f1f5",
            }}
          >
            <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
              <UploadDiv>
                <img src={ComputerIcon} alt="computer" /> Upload from computer
              </UploadDiv>
            </label>
            <AvatarDriveFilePicker onFilesSelected={handleDriveFiles} />
          </div>
        </Popover>
        <TextArea
          placeholder="Create a leave email"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          bordered={false}
          style={{ flex: 1, fontSize: "16px", height: "132px", resize: "none" }}
        />
        <Button
          icon={<ArrowUpOutlined />}
          type="primary"
          shape="circle"
          onClick={handleSend}
          disabled={!message.trim() && !file}
          style={{
            backgroundColor: message.trim() || file ? "#3F8CFF" : "#D9D9D9",
            border: "none",
          }}
        />
      </Flex>
      {file && (
        <Flex
          align="center"
          style={{
            marginTop: "8px",
            padding: "8px",
            borderRadius: "8px",
            backgroundColor: "#F5F5F5",
          }}
        >
          <FileOutlined style={{ fontSize: "18px", color: "#595959" }} />
          <Typography.Text style={{ marginLeft: "8px", flex: 1 }}>
            {file.name}
          </Typography.Text>
          <Button
            icon={<CloseOutlined />}
            type="text"
            onClick={handleRemoveFile}
            style={{ color: "#FF4D4F" }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default ChatInput;
