import React, { useReducer, useState, useRef, useEffect } from "react";
import { Popover, TextField } from "@mui/material";
import {
  ToolBarContainer,
  ToolConfigurationHeader,
  ToolConfigurationBody,
  ToolConfigurationFooter,
  ToolConfigurationSaveButton,
} from "./style";
import CloseIcon from "../../assets/workflows/x-white-icon.svg";
import { MessageContainer, UserMessage, AIMessage, AICircle } from "./style";

const messagesReducer = (state, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return [...state, action.message];
    case "UPDATE_LAST_MESSAGE":
      return [...state.slice(0, -1), action.message];
    default:
      return state;
  }
};

const ChatTest = ({ anchorElChat, onCloseChat, workflow }) => {
  const [messages, dispatch] = useReducer(messagesReducer, []);
  const [userQuery, setUserQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messageContainerRef = useRef(null);

  console.log("workflow", workflow);

  useEffect(() => {
    if (anchorElChat && workflow?.name) {
      const initialMessage = {
        role: "human",
        content: `Execute the ${workflow.name}`,
      };
      dispatch({ type: "ADD_MESSAGE", message: initialMessage });
      handleSendMessage(initialMessage);
    }
  }, [anchorElChat, workflow?.name]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async (initialMessage = null) => {
    const newMessage = initialMessage || {
      role: "human",
      content: userQuery.trim(),
    };
    
    if (!initialMessage) {
      await dispatch({ type: "ADD_MESSAGE", message: newMessage });
      setUserQuery("");
    }
    scrollToBottom();

    const payload = {
      user_id: localStorage.getItem("id"),
      user_email: localStorage.getItem("email"),
      user_name: localStorage.getItem("name"),
      workflow_id: workflow?._id,
      language: "english",
      timestamp: new Date().toISOString(),
      document_urls: [],
      messages: [...messages, newMessage],
    };

    try {
      setIsLoading(true);
      const loadingMessage = {
        role: "ai",
        content: "Loading...",
      };
      dispatch({ type: "ADD_MESSAGE", message: loadingMessage });
      scrollToBottom();
      const response = await fetch(
        "https://assistant-api.aiflowpro.io/workflows",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        const updatedMessage = {
          role: "ai",
          content: data.text,
        };
        dispatch({ type: "UPDATE_LAST_MESSAGE", message: updatedMessage });
        scrollToBottom();
      }
    } catch (error) {
      console.error("Error fetching workflow:", error);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  return (
    <Popover
      open={Boolean(anchorElChat)}
      anchorEl={anchorElChat}
      onClose={onCloseChat}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "1rem",
            boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.08)",
          },
        },
      }}
    >
      <ToolBarContainer style={{ width: "356px" }}>
        <ToolConfigurationHeader>
          <div>{workflow?.name}</div>
          <img src={CloseIcon} alt="Close" onClick={onCloseChat} />
        </ToolConfigurationHeader>
        <ToolConfigurationBody
          ref={messageContainerRef}
          style={{ height: "350px", overflowY: "auto", gap: "8px" }}
        >
          {messages.map((message, index) => (
            <MessageContainer key={index}>
              {message.role === "human" ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <UserMessage>{message.content}</UserMessage>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <circle cx="12" cy="12.5" r="12" fill="#1A74DB" />
                  </svg>
                  <AIMessage>{message.content}</AIMessage>
                </div>
              )}
            </MessageContainer>
          ))}
        </ToolConfigurationBody>
        <ToolConfigurationFooter style={{ justifyContent: "space-between" }}>
          <TextField
            label="Type a message..."
            fullWidth
            size="small"
            value={userQuery}
            onChange={(e) => setUserQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !isLoading && userQuery.trim() !== "") {
                handleSendMessage();
              }
            }}
          />
          <ToolConfigurationSaveButton
            variant="contained"
            color="primary"
            disabled={!userQuery || isLoading}
            onClick={handleSendMessage}
          >
            Send
          </ToolConfigurationSaveButton>
        </ToolConfigurationFooter>
      </ToolBarContainer>
    </Popover>
  );
};
export default ChatTest;
